// Check if APP_DEBUG is false and APP_URL does not contain 'test' or 'localhost'
export default function isProduction() {
    const env = process?.env ?? {};

    const isDebug = env.APP_DEBUG == 'true';

    const appUrl = env.APP_URL ?? new URL(location.href).origin;

    return (
        !isDebug && !appUrl.includes('test') && !appUrl.includes('localhost')
    );
}
